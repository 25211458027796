<template id="r-lost">
  <section class="container">
    <h1 :class="[isAndroid ? '' : 'font-weight-bolder']">404</h1>
    <p>Документ не знайдено, 
      <router-link to="/">перейти на головну сторінку</router-link>.
    </p>
    <div class="absolute-center zoom">
      <div class="rotation position-absolute"><img class="img-fluid" style="--i:0; --total:3" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1959313/spacesuit1.png" draggable="false"></div>
      <div class="rotation position-absolute"><img class="img-fluid" style="--i:1; --total:3" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1959313/spacesuit2.png" draggable="false"></div>
      <div class="rotation position-absolute"><img class="img-fluid" style="--i:2; --total:3" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1959313/spacesuit3.png" draggable="false"></div>
    </div>
  </section>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('app', ['isAndroid']),
  },
}
</script>